import { Button, Flex, Input, Text } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { colors } from "../helpers/colors";
import { useState } from "react";
import { apiRegisterUser } from "../helpers/api/apiRegisterUser";
import { ToastContainer } from "react-toastify";

export const RegisterUser = () => {
  const [flightNumber, setFlightNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [loading, setLoading] = useState(false);

  const handleRegistration = () => {
    if (firstName && lastName && flightNumber && passportNumber) {
      setLoading(true);
      apiRegisterUser(
        firstName,
        lastName,
        passportNumber,
        flightNumber,
        setLoading
      );
    } else {
      alert("All inputs are required");
    }
  };

  return (
    <Flex flexDir="column" w="100%" alignItems={"center"} mt={10}>
      <Header />

      <Flex flexDir="column" ml={5} alignItems="center" mb={20}>
        <Text fontSize={40} color={colors.text} mb={10} fontWeight={"semibold"}>
          Register Passenger
        </Text>

        <Input
          color={colors.text}
          w="100%"
          placeholder="Flight number"
          border={`1px solid ${colors.text}`}
          mb={3}
          onChange={(e) => {
            setFlightNumber(e.target.value);
          }}
          value={flightNumber}
        />

        <Input
          color={colors.text}
          w="100%"
          placeholder="Passport number"
          border={`1px solid ${colors.text}`}
          mb={3}
          onChange={(e) => {
            setPassportNumber(e.target.value);
          }}
          value={passportNumber}
        />

        <Flex
          w="100%"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Input
            color={colors.text}
            w="49%"
            placeholder="First name"
            border={`1px solid ${colors.text}`}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            value={firstName}
          />
          <Input
            color={colors.text}
            w="49%"
            placeholder="Last name"
            border={`1px solid ${colors.text}`}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            value={lastName}
          />
        </Flex>

        <Button
          isLoading={loading}
          w="fit-content"
          color={colors.bgColor}
          _hover={{ bgColor: "gray" }}
          onClick={handleRegistration}
        >
          Submit
        </Button>
      </Flex>

      <Flex style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        <object
          id="pdf-object"
          data={`${require("../assets/structure.pdf")}#toolbar=0&sidebar=0`}
          type="application/pdf"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <p>Sorry, your browser doesn't support embedded PDFs.</p>
        </object>
      </Flex>
    </Flex>
  );
};
