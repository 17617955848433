import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { colors } from "../helpers/colors";
import { logout } from "../helpers/logout";

export const Header = ({ noMargin }: { noMargin?: boolean }) => {
  const navItems = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Search Flight",
      path: "/pick-winner",
    },
    {
      name: "Registration",
      path: "/register",
    },
  ];

  return (
    <Flex
      w="75%"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      mb={!noMargin ? 40 : 10}
    >
      <Image
        src={require("../assets/app-logo.png")}
        w={48}
        onClick={() => {
          window.location.href = "/";
        }}
        cursor="pointer"
      />

      <Flex flexDir="row" alignItems="center">
        {navItems.map((item, index) => (
          <Text
            key={index}
            fontSize={18}
            fontWeight="bold"
            color={colors.text}
            opacity={window.location.pathname === item.path ? 0.7 : 1}
            ml={10}
            cursor="pointer"
            _hover={{
              opacity: 0.7,
            }}
            onClick={() => {
              window.location.href = item.path;
            }}
          >
            {item.name}
          </Text>
        ))}

        <Image
          src={require("../assets/emirates-icon.png")}
          w={"60px"}
          h={"50px"}
          ml={8}
          cursor="pointer"
          onClick={() => {
            window.location.href = "/emirates";
          }}
        />
        <Image
          src={require("../assets/ddf-icon.png")}
          w={"70px"}
          ml={8}
          cursor="pointer"
          onClick={() => {
            window.location.href = "/ddf";
          }}
        />
        <Button
          fontSize={18}
          fontWeight="bold"
          bgColor={"gray"}
          color={colors.text}
          ml={14}
          cursor="pointer"
          _hover={{
            opacity: 0.7,
          }}
          onClick={logout}
        >
          Logout
        </Button>
      </Flex>
    </Flex>
  );
};
