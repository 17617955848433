import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import { colors } from "../helpers/colors";
import { useEffect, useState } from "react";
import { apiGetAllFlights } from "../helpers/api/apiGetAllFlights";
import { CloseIcon } from "@chakra-ui/icons";

const flights: any = [
  {
    DATE: "19.7.2023",
    "FLIGHT TIME": " 07:56:00",
    "FLIGHT NUMBER": "EK001",
    "PASS CAP": 519,
    "AIRCRAFT TYPE": "A380",
    ROUTE: "DXB-LHR",
    STATUS: "INFLIGHT ",
    "PURCHASED TICKET +": 505,
    "VALUE TICKET +": 5044.95,
    "PRIZE SPLIT": 5,
    "REWARD AMOUNT ": 840.825,
    PAYOUT: 4204.125,
    NETT: 840.8249999999998,
    "%": 0.9730250481695568,
  },
  {
    DATE: "19..7.2023",
    "FLIGHT TIME": "  05:55:00",
    "FLIGHT NUMBER": "EK145",
    "PASS CAP": 494,
    "AIRCRAFT TYPE": "B777",
    ROUTE: "DXB-AMS",
    STATUS: "INFLIGHT ",
    "PURCHASED TICKET +": 490,
    "VALUE TICKET +": 4895.1,
    "PRIZE SPLIT": 4,
    "REWARD AMOUNT ": 979.0200000000001,
    PAYOUT: 3916.0800000000004,
    NETT: 979.02,
    "%": 0.9919028340080972,
  },
  {
    DATE: "19.7.2023",
    "FLIGHT TIME": "  04:26:00",
    "FLIGHT NUMBER": "EK75",
    "PASS CAP": 519,
    "AIRCRAFT TYPE": "A380",
    ROUTE: "DXB-CDG",
    STATUS: "BOARDING",
    "PURCHASED TICKET +": 504,
    "VALUE TICKET +": 5034.96,
    "PRIZE SPLIT": 5,
    "REWARD AMOUNT ": 839.16,
    PAYOUT: 4195.8,
    NETT: 839.1599999999999,
    "%": 0.9710982658959537,
  },
  {
    DATE: "19..7.2023",
    "FLIGHT TIME": "  12:00:00",
    "FLIGHT NUMBER": "EK921",
    "PASS CAP": 386,
    "AIRCRAFT TYPE": "777-300ER",
    ROUTE: "DXB-CAI",
    STATUS: "INFLIGHT ",
    "PURCHASED TICKET +": 384,
    "VALUE TICKET +": 3836.16,
    "PRIZE SPLIT": 3,
    "REWARD AMOUNT ": 959.04,
    PAYOUT: 2877.12,
    NETT: 959.04,
    "%": 0.9948186528497409,
  },
  {
    DATE: "19.7.2023",
    "FLIGHT TIME": "  12:00:00",
    "FLIGHT NUMBER": "EK2094",
    "PASS CAP": 178,
    "AIRCRAFT TYPE": "737 MAX 8",
    ROUTE: "DXB-IST",
    STATUS: "INFLIGHT ",
    "PURCHASED TICKET +": 175,
    "VALUE TICKET +": 1748.25,
    "PRIZE SPLIT": 2,
    "REWARD AMOUNT ": 582.75,
    PAYOUT: 1165.5,
    NETT: 582.75,
    "%": 0.9831460674157303,
  },
  {
    DATE: "19..7.2023",
    "FLIGHT TIME": "  12:50:01",
    "FLIGHT NUMBER": "EK9884",
    "PASS CAP": 519,
    "AIRCRAFT TYPE": "A380",
    ROUTE: "DXB-HKG",
    STATUS: "BOARDING",
    "PURCHASED TICKET +": 501,
    "VALUE TICKET +": 5004.99,
    "PRIZE SPLIT": 5,
    "REWARD AMOUNT ": 834.165,
    PAYOUT: 4170.825,
    NETT: 834.165,
    "%": 0.9653179190751445,
  },
  {
    DATE: "19.7.2023",
    "FLIGHT TIME": "  12:55:02",
    "FLIGHT NUMBER": "EK003",
    "PASS CAP": 519,
    "AIRCRAFT TYPE": "A380",
    ROUTE: "DXB-LHR",
    STATUS: "INFLIGHT ",
    "PURCHASED TICKET +": 507,
    "VALUE TICKET +": 5064.93,
    "PRIZE SPLIT": 5,
    "REWARD AMOUNT ": 844.1550000000001,
    PAYOUT: 4220.775000000001,
    NETT: 844.1549999999997,
    "%": 0.976878612716763,
  },
];

export const SearchFlight = () => {
  const [loading, setLoading] = useState(false);
  // const [flights, setFlights] = useState<any>([]);
  const [flightNumber, setFlightNumber] = useState("");
  const [filteredFlights, setFilteredFlights] = useState<any>([]);
  const [showWinner, setShowWinner] = useState(false);

  useEffect(() => {
    setFilteredFlights(flights);
  }, []);

  const handleSearch = (searchTerm: any) => {
    setLoading(true);

    if (searchTerm) {
      setFilteredFlights(
        flights.filter((flight: any) => {
          console.log(
            flight["FLIGHT NUMBER"].toUpperCase(),
            searchTerm.toUpperCase()
          );

          return (
            flight["FLIGHT NUMBER"].toUpperCase() === searchTerm.toUpperCase()
          );
        })
      );
    } else {
      setFilteredFlights(flights);
    }

    setLoading(false);
  };

  const handleWinnerPick = () => {
    setShowWinner(true);
  };

  return (
    <Flex flexDir="column" w="100%" alignItems={"center"} mt={10} mb={20}>
      <Header />

      <Flex flexDir="column" ml={5} alignItems="center" w="90%">
        <Flex
          flexDir="row"
          w="93%"
          alignItems="center"
          justifyContent="space-between"
          mb={10}
        >
          <Text fontSize={30} color={colors.text} fontWeight={"semibold"}>
            Search By Flight Number
          </Text>

          <Flex
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
            w="40%"
          >
            {flightNumber && (
              <CloseIcon
                m={3}
                cursor="pointer"
                // onClick={async () => {
                //   handleSearch(false);
                //   setFlightNumber("");
                // }}
              />
            )}
            <Input
              color={colors.text}
              w="100%"
              placeholder="Flight number"
              border={`1px solid ${colors.text}`}
              onChange={(e) => {
                setFlightNumber(e.target.value);
              }}
              value={flightNumber}
            />
            <Button
              isLoading={loading}
              w="fit-content"
              color={colors.bgColor}
              _hover={{ bgColor: "gray" }}
              // onClick={() => {
              //   handleSearch(flightNumber);
              // }}
            >
              Search
            </Button>
          </Flex>
        </Flex>

        <Table variant="simple" size="sm" w="100%" mb={5}>
          <Thead>
            <Tr>
              <Th color="gray">Date</Th>
              <Th color="gray">Flight Time</Th>
              <Th color="gray">Flight Number</Th>
              <Th color="gray">Passengers</Th>
              <Th color="gray">Aircraft Type</Th>
              <Th color="gray">Route</Th>
              <Th color="gray">Status</Th>
              <Th color="gray">Purchased Ticket Plus</Th>
              <Th color="gray">Ticket Plus value</Th>
              <Th color="gray">PRIZE SPLIT</Th>
              <Th color="gray">PAYOUT</Th>
              <Th color="gray">NETT</Th>
              <Th color="gray">Percentage</Th>
              <Th color="gray">Reveal Winner</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredFlights.map((flight: any, idx: number) => (
              <Tr key={idx} flexDir="column">
                <Td>{flight.DATE}</Td>
                <Td>{flight["FLIGHT TIME"]}</Td>
                <Td>{flight["FLIGHT NUMBER"]}</Td>
                <Td>{flight["PASS CAP"]}</Td>
                <Td>{flight["AIRCRAFT TYPE"]}</Td>
                <Td>{flight["ROUTE"]}</Td>
                <Td>{flight["STATUS"]}</Td>
                <Td>{Math.round(flight["PURCHASED TICKET +"])}</Td>
                <Td>${Math.round(flight["VALUE TICKET +"])}</Td>
                <Td>{flight["PRIZE SPLIT"]}</Td>
                <Td>${Math.round(flight["PAYOUT"])}</Td>
                <Td>${Math.round(flight["NETT"])}</Td>
                <Td>{Math.round(flight["%"] * 100)}%</Td>
                <Td>
                  <Button color="#000" onClick={handleWinnerPick}>
                    Winner
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>

          <Tbody>
            <Tr flexDir="column">
              <Td>Summary</Td>
              <Td></Td>
              <Td></Td>
              <Td>3134</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>3066</Td>
              <Td>$30,629</Td>
              <Td></Td>
              <Td>$24,750</Td>
              <Td>$5,879</Td>
              <Td>98%</Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>

        {showWinner && (
          <Modal
            isOpen={showWinner}
            onClose={() => {
              setShowWinner(false);
            }}
            isCentered
          >
            <ModalOverlay />
            <ModalContent p={0}>
              <ModalHeader color="#000" alignSelf="center" fontSize={22} mb={8}>
                Ticket Plus Winners
              </ModalHeader>
              <ModalBody color="#000">
                <Flex
                  w="100%"
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <Flex flexDir="column" mb={10}>
                    <Text fontSize={20} fontWeight={"bolder"}>
                      Winner 1
                    </Text>
                    <Text>
                      Full Name:{" "}
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        Person One
                      </span>
                    </Text>
                    <Text>
                      Passport Number:{" "}
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        1111101010101
                      </span>
                    </Text>
                    <Text>
                      Boarding Pass:{" "}
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        222020202
                      </span>
                    </Text>
                  </Flex>

                  <Flex flexDir="column">
                    <Text fontSize={20} fontWeight={"bold"}>
                      Winner 2
                    </Text>
                    <Text>
                      Full Name:{" "}
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        Person Two
                      </span>
                    </Text>
                    <Text>
                      Passport Number:{" "}
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        999990909099
                      </span>
                    </Text>
                    <Text>
                      Boarding Pass:{" "}
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        7676767676767
                      </span>
                    </Text>
                  </Flex>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setShowWinner(false);
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Flex>
    </Flex>
  );
};
