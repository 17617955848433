import { Flex, Image, Text } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { colors } from "../helpers/colors";

export const Home = () => {
  return (
    <Flex flexDir="column" w="100%" alignItems={"center"} mt={10}>
      <Header />
      <Image src={require("../assets/app-logo.png")} w={"500px"} mt={20} />
    </Flex>
  );
};
