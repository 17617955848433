import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { colors } from "./helpers/colors";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: colors.bgColor,
        color: colors.text,
      },
    },
  },

  fonts: {
    body: `'Mulish'sans-serif`,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
