import axios from "axios";
import generateUniqueId from "../generateUniqueId";

export const apiRegisterUser = async (
  firstName: string,
  lastName: string,
  passportNumber: string,
  flightNumber: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/register`, {
      firstName,
      lastName,
      passportNumber,
      flightNumber,
    })
    .then((response) => {
      console.log(response.data);
      setLoading(false);
      alert("Passenger added");
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
};
