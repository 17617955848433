import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { RegisterUser } from "./screens/RegisterUser";
import { About } from "./screens/About";
import { Home } from "./screens/Home";
import { Login } from "./screens/Login";
import { useEffect, useState } from "react";
import { SearchFlight } from "./screens/SearchFlight";
import { Emirates } from "./screens/Emirates";
import { DDF } from "./screens/DDF";

function App() {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("logged-in"));

  // useEffect(() =>{
  //   setLoggedIn(localStorage.getItem('logged-in'))
  // }, [])

  return (
    <BrowserRouter>
      <Routes>
        {loggedIn === "true" ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/pick-winner" element={<SearchFlight />} />
            <Route path="/register" element={<RegisterUser />} />
            <Route path="/emirates" element={<Emirates />} />
            <Route path="/ddf" element={<DDF />} />
          </>
        ) : (
          <Route path="/" element={<Login />} />
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
