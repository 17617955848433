import { Flex, Text, Input, Button, Image } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { useState } from "react";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    if (username && password) {
      if (
        username === process.env.REACT_APP_USERNAME &&
        password === process.env.REACT_APP_PASSWORD
      ) {
        await localStorage.setItem("logged-in", "true");

        window.location.reload();
      } else {
        console.log(
          username,
          process.env.REACT_APP_USERNAME,
          password,
          process.env.REACT_APP_PASSWORD
        );
        alert("Username or password invalid, Try again");
      }
    } else {
      alert("Invalid input, fill in your email and password");
    }
  };

  return (
    <Flex flexDir="column" w="100%" alignItems={"center"} mt={10}>
      {/* <Header /> */}

      <Image src={require("../assets/app-logo.png")} w={"350px"} mt={20} />

      <Flex flexDir="column" w="25%" mt={20} alignItems="center">
        <Input
          placeholder="Username"
          mb={5}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          value={username}
        />
        <Input
          type="password"
          placeholder="Password"
          mb={5}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
        />
        <Button colorScheme="blue" w="fit-content" onClick={handleLogin}>
          Login
        </Button>
      </Flex>
    </Flex>
  );
};
