import { Flex, Text } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { colors } from "../helpers/colors";

export const About = () => {
  return (
    <Flex
      flexDir="column"
      w="100%"
      alignItems={"center"}
      mt={10}
      overflowY={"hidden"}
    >
      <Header noMargin />

      <Flex style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        <object
          id="pdf-object"
          data={`${require("../assets/about.pdf")}#toolbar=0&sidebar=0`}
          type="application/pdf"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <p>Sorry, your browser doesn't support embedded PDFs.</p>
        </object>
      </Flex>
    </Flex>
  );
};
